import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectOrganisationId } from '../auth/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { getMeasureSign } from '../../utilities/general-helper';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { NewTankDeliveryEntity, TankDeliveryResponse } from '../../entities/tankDelivery';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { NewTankDeliveryModel, TankDeliveryModel } from '../../models/tankDeliveryModel';
import { setTankDeliveryData, setTankDeliveryError, setTankDeliveryStatus } from './reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { selectSelectedSiteId } from '../sites/selectors';
import { Messages } from '../../constants/messages';
import * as actions from './actions';
import * as services from './services';
import * as dateTimeHelper from '../../utilities/datetime-helper';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TANK_DELIVERIES, loadTankDeliveries);
  yield takeLatest(actions.CREATE_TANK_DELIVERY, createTankDelivery);
}

export function* loadTankDeliveries(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setTankDeliveryStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: TankDeliveryResponse = yield call(services.getTankDeliveries, action.payload, organisationId);
      let data: TankDeliveryModel[] = yield call(mapTankDeliveryEntityToTankDeliveryModel, response);
      yield put(setTankDeliveryData(data));
      yield put(setTankDeliveryStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTankDeliveryError());
    yield put(clearAllFieldValidation());
  }
}

export function* createTankDelivery(action: PayloadAction<NewTankDeliveryModel>) {
  try {
    if (!!action.payload) {
      yield put(showBackdrop());
      yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
      const organisationId: string = yield select(selectOrganisationId);
      const siteId: string = yield select(selectSelectedSiteId);
      let newTankDelivery: NewTankDeliveryEntity = mapNewTankDeliveryModelToEntity(
        action.payload,
        organisationId,
        siteId
      );
      yield call(services.createNewTankDelivery, action.payload.tankId, newTankDelivery);
      yield put(setIsPageDirty(false));
      yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
      yield delay(10);
      yield put(setSnackBarSuccess(Messages.TANK_DELIVERY_SUCCESS));
      yield put(hideBackdrop());
    }
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

const mapTankDeliveryEntityToTankDeliveryModel = (response: TankDeliveryResponse) => {
  if (response && response.items.length > 0) {
    const result: TankDeliveryModel[] = response.items.map((tank, i) => {
      return {
        id: tank.id,
        organisationId: tank.organisationId,
        siteId: tank.siteId,
        tankId: tank.tankId,
        start: tank.start,
        end: tank.end,

        productVolumeDifference:
          tank.end?.product?.volume?.value - tank.start?.product?.volume?.value > 0
            ? `+ ${
                Math.round((tank.end?.product?.volume?.value - tank.start?.product?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.product?.volume?.value - tank.start?.product?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`,

        productTCVolumeDifference:
          tank.end?.product?.volume?.temperatureCompensatedValue -
            tank.start?.product?.volume?.temperatureCompensatedValue >
          0
            ? `+ ${
                Math.round(
                  (tank.end?.product?.volume?.temperatureCompensatedValue -
                    tank.start?.product?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`
            : `${
                Math.round(
                  (tank.end?.product?.volume?.temperatureCompensatedValue -
                    tank.start?.product?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`,

        productHeightDifference:
          tank.end?.product?.height?.value - tank.start?.product?.height?.value > 0
            ? `+ ${
                Math.round((tank.end?.product?.height?.value - tank.start?.product?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.height?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.product?.height?.value - tank.start?.product?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.height?.unitOfMeasure)}`,

        waterVolumeDifference:
          tank.end?.water?.volume?.value - tank.start?.water?.volume?.value > 0
            ? `+ ${
                Math.round((tank.end?.water?.volume?.value - tank.start?.water?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.water?.volume?.value - tank.start?.water?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`,

        waterTCVolumeDifference:
          tank.end?.water?.volume?.temperatureCompensatedValue -
            tank.start?.water?.volume?.temperatureCompensatedValue >
          0
            ? `+ ${
                Math.round(
                  (tank.end?.water?.volume?.temperatureCompensatedValue -
                    tank.start?.water?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`
            : `${
                Math.round(
                  (tank.end?.water?.volume?.temperatureCompensatedValue -
                    tank.start?.water?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`,

        waterHeightDifference:
          tank.end?.water?.height?.value - tank.start?.water?.height?.value > 0
            ? `+ ${
                Math.round((tank.end?.water?.height?.value - tank.start?.water?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.height?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.water?.height?.value - tank.start?.water?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.height?.unitOfMeasure)}`,

        temperatureDifference:
          tank.end?.temperature?.value - tank.start?.temperature?.value > 0
            ? `+ ${
                Math.round((tank.end?.temperature?.value - tank.start?.temperature?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.temperature?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.temperature?.value - tank.start?.temperature?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.temperature?.unitOfMeasure)}`,
      } as TankDeliveryModel;
    });

    return result;
  }
  return [] as TankDeliveryModel[];
};

const mapNewTankDeliveryModelToEntity = (newDelivery: NewTankDeliveryModel, organisationId: string, siteId: string) => {
  if (newDelivery) {
    return {
      organisationId: organisationId,
      siteId: siteId,
      type: newDelivery?.type,
      start: {
        dateTimeUtc: dateTimeHelper.convertEndDateStringtoUTCString(newDelivery?.dateTime),
        product: {
          volume: {
            value: newDelivery?.currentLevel,
            unitOfMeasure:
              newDelivery?.type === 'Delivery'
                ? newDelivery?.deliveredUnitOfMeasure
                : newDelivery?.desiredUnitOfMeasure,
          },
        },
      },
      end: {
        dateTimeUtc: dateTimeHelper.convertEndDateStringtoUTCString(newDelivery?.dateTime),
        product: {
          volume: {
            value:
              newDelivery?.type === 'Delivery'
                ? +newDelivery?.deliveredVolume + +newDelivery?.currentLevel
                : +newDelivery?.desiredLevel,
            unitOfMeasure:
              newDelivery?.type === 'Delivery'
                ? newDelivery?.deliveredUnitOfMeasure
                : newDelivery?.desiredUnitOfMeasure,
          },
        },
      },
    } as NewTankDeliveryEntity;
  }
  return {} as NewTankDeliveryEntity;
};
