import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducers';
import { selectTankDeliveryData, selectTankDeliveryStatus } from '../../store/tank-deliveries/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectTankData, selectTankInfo } from '../../store/tanks/selectors';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import * as actions from '../../store/tank-histories/actions';
import * as tankDeliveryActions from '../../store/tank-deliveries/actions';
import * as tankActions from '../../store/tanks/actions';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import TankDeliveryComponent from './tank-deliveries';

const mapStateToProps = (state: RootState) => {
  return {
    tankDeliveryData: selectTankDeliveryData(state),
    tankDeliveryStatus: selectTankDeliveryStatus(state),
    tankInfo: selectTankInfo(state),
    tankList: selectTankData(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTankStatusHistories: (data: string) => dispatch({ type: actions.LOAD_TANK_STATUS_HISTORIES, payload: data }),
    loadTankDeliveries: (data: string) => dispatch({ type: tankDeliveryActions.LOAD_TANK_DELIVERIES, payload: data }),
    loadTankInfo: (id: string) => dispatch({ type: tankActions.LOAD_TANK_INFO, payload: id }),
    loadTanks: (data: string) => dispatch({ type: tankActions.LOAD_TANKS, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const TankDeliveryList = connect(mapStateToProps, mapDispatchToProps)(TankDeliveryComponent);

export default TankDeliveryList;
